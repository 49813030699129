import { Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import CustomInput from "../../../components/UI/CustomInput";

export default function ExposureAndCreditLimit({
  isCreditLimit,
  payload,
  setPayload,
  clientData,
}) {
  return (
    <Grid gap={1} fontSize="sm" templateColumns="repeat(2,1fr)">
      <GridItem>
        <Text>Old Limit</Text>
      </GridItem>
      <GridItem>
        <CustomInput
          value={
            !isCreditLimit
              ? payload?.user?.exposureLimit
              : payload?.user?.creditReference
          }
          disabled
        />
      </GridItem>
      <GridItem>
        <Text>New Limit</Text>
      </GridItem>
      <GridItem>
        <CustomInput
          onChange={(e) => {
            setPayload({
              ...payload,
              newLimit: e.target.value,
            });
          }}
        />
      </GridItem>
      <GridItem>
        <Text>Transaction Password</Text>
      </GridItem>
      <GridItem>
        <CustomInput
          type="password"
          onChange={(e) => {
            setPayload({
              ...payload,
              transactionPassword: e.target.value,
            });
          }}
        />
      </GridItem>
    </Grid>
  );
}
