import { AXIOS_INSTANCE } from ".";
import { BASE_URL, getToken } from "../utils/constants";

export const loginAPI = async (username, password) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(`${BASE_URL}/auth/signin`, {
      username,
      password,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserBetsAPI = async (
  search,
  gameType = "CRICKET",
  pageNumber = 0,
  pageSize = 100
) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/unsettled-bets`,
      {
        betStatus: "MATCHED",
        pageNumber,
        pageSize,
        search,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const changePasswordAPI = async (payload, token) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/password`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllMatchListAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/admin/all-sports`);
    return data;
  } catch (error) {
    return error;
  }
};
export const betHistoryAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/bet-history`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const accountLogAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/account-log`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getProfitLossReportAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/profit-loss-report`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getLiveStreamingUrlAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/streaming/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const marketAnalysisAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/market-analysis-report`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const searchUserAPI = async (user) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/admin/search-user`, {
      params: {
        search: user,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const generalReportAPI = async (type) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/general-report`,
      {
        params: {
          type,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const unsettledBetsAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/unsettled-bets`,
      {
        ...payload,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserDetailsAPI = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/search/user-detail`,
      {
        params: {
          userName: id,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserDataAPI = async (userId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/accmanage/user/${userId}`
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getActivityLogAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/activity-log`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetsFromAccountLogs = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/admin/bets-from-log`,
      {
        params: {
          accountLogId: id,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getBetHistoryAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/admin/bet-history`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const checkIfUserNameExistsAPI = async (userName) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${BASE_URL}/auth/valid/username/${userName}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const generateMFAAPI = async (password) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${BASE_URL}/user/generate-mfacode`,
      {
        password,
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const resendCodeAPI = async (userName) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/resend-otp`,
      {},
      {
        params: {
          userName,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const verifyCodeAPI = async (userName, otp, isDisableMfa = false) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${BASE_URL}/auth/verify-otp`,
      {},
      {
        params: {
          userName,
          otp,
          isDisableMfa,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const disableMFAAPI = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${BASE_URL}/user/disable-mfa`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};