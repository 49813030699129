import { Alert, Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { loginAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { generateHash } from "../service";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const login = async () => {
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    setLoading(true);
    const { response, message, code } = await loginAPI(
      userName,
      Encrypt(password)
    );
    if (userName === "" || password === "") {
      toast.error("Please enter username and password");
      return;
    }
    if (code == 200) {
      localStorage.setItem("token", response.token);
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });

      if (response?.mfaEnabled) {
        navigate("/otp", {
          state: { userName },
        });
        setLoading(false);
      } else {
        toast.success("Login successful");
        setTimeout(() => {
          window.location.href = "/admin/market-analysis";
        }, 1200);
        setLoading(false);
      }
    } else {
      toast.error(response);
      setLoading(false);
    }
  };
  return (
    <Flex direction="column" className="login_container">
      <Box mb="20px" className="login_logo">
        <img src={require("../assets/img/logo-b.png")} />
      </Box>
      <Flex direction="column" className="login_box">
        <h2>Sign In</h2>
        <Input
          type="email"
          onKeyDown={handleKeyDown}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="Username"
          name="username"
        />
        <Input
          onKeyDown={handleKeyDown}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
          name="password"
        />
        <Button disabled={loading} onKeyDown={handleKeyDown} onClick={login}>
          <Text>Login</Text>
          {loading && <i className="fas fa-circle-notch fa-spin"></i>}
        </Button>
      </Flex>
    </Flex>
  );
}
