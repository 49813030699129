import { Box, Button, Text, useToast, WrapItem } from "@chakra-ui/react";
import React from "react";
import CustomInput from "../../components/UI/CustomInput";
import CustomTable from "../../components/UI/Table";
import {
  getProfitLossReportAPI,
  searchUserAPI,
} from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import AsyncSelect from "react-select/async";
import moment from "moment";
export default function ProfitLoss() {
  const [searchClientName, setSearchClientName] = React.useState("");
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [userName, setUserName] = React.useState(null);
  const [profitLossData, setProfitLossData] = React.useState([]);
  const toast = useToast();
  const getProfitLoss = async () => {
    let payload = {
      from: new Date(fromDate),
      to: new Date(toDate),
    };
    if (userName != null && userName?.value?.length > 0) {
      payload["userName"] = userName.value;
    }
    const { response, message, code } = await getProfitLossReportAPI(payload);
    if (code == 200) {
      setProfitLossData(response);
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };

  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div data-select2-id={6}>
              <div className="listing-grid" data-select2-id={5}>
                <div className="detail-row" data-select2-id={4}>
                  <h2>Profit Loss</h2>
                  <div
                    method="post"
                    id="form-search"
                    data-select2-id="form-search"
                  >
                    <div className="m-t-20" data-select2-id={3}>
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="list-ac">
                              Search By Client Name
                            </label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={searchClient}
                              onChange={(e) => setUserName(e)}
                              value={userName}
                              isClearable
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="search-from_date">From:</label>
                            <input
                              type="date"
                              className="form-control has-feedback-left date"
                              name="search-from_date"
                              id="search-from_date"
                              placeholder="Choose Date"
                              aria-describedby="inputSuccess2Status"
                              autoComplete="off"
                              value={fromDate}
                              onChange={(e) => setFromDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="search-to_date">To:</label>
                            <input
                              type="date"
                              className="form-control has-feedback-left date"
                              name="search-to_date"
                              id="search-to_date"
                              placeholder="Choose Date"
                              aria-describedby="inputSuccess2Status"
                              autoComplete="off"
                              value={toDate}
                              onChange={(e) => setToDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label style={{ width: "100%" }}>&nbsp;</label>
                          <button
                            onClick={getProfitLoss}
                            className="btn btn-diamond"
                            id="loaddata"
                          >
                            Load
                          </button>
                          &nbsp;&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="page_contents" style={{ display: "block" }}>
                  <div className="pl-boxes">
                    <h4 style={{ marginLeft: "5px" }}>
                      Profit &amp; Loss For Event Type
                    </h4>
                    <div id="pl-box-html" />
                  </div>
                  <table
                    id="example"
                    className="custom-table table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Sports</th>
                        <th>Markets</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody id="table_body">
                      {profitLossData.length == 0 ? (
                        <tr>
                          <td colSpan="100%" align="center">
                            Nothing to show...
                          </td>
                        </tr>
                      ) : (
                        profitLossData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.eventName}</td>
                            <td>{item.eventType}</td>
                            <td
                              style={{
                                color: item.amount > 0 ? "green" : "red",
                              }}
                            >
                              {item.amount}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <div className="col-md-12 m-t-10 m-b-10">
                    <div className="col-md-6" />
                    <div className="col-md-6" id="pagination" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="modal-profit_loss">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="title-profit_loss" />
                  <button type="button" className="close" data-dismiss="modal">
                    ×
                  </button>
                </div>
                <div className="modal-body">
                  <table
                    className="table table-striped table-bordered"
                    id="popupData"
                  >
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>TranDate</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Closing</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody id="tbody-profit_loss"></tbody>
                  </table>
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  /*  return (
    <Box p="5">
      <Text>Profit Loss</Text>
      <WrapItem m="2" alignItems="flex-end">
        <CustomInput
          size="md"
          label="Search By Client Name"
          placeholder="search client"
        />
        <CustomInput size="md" label="From" />
        <CustomInput size="md" label="To" />
        <Button ml="5">Load</Button>
      </WrapItem>
      <CustomTable
        columns={[
          {
            name: "Game Name",
            id: "gameName",
          },
          {
            name: "Game Type",
            id: "gameType",
          },
          {
            name: "Profit & Loss",
            id: "profitLoss",
          },
        ]}
        rows={[]}
      />
    </Box>
  ); */
}
