import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { accountLogAPI, searchUserAPI } from "../../service/AuthService";
import AsyncSelect from "react-select/async";
import moment from "moment";
import ClientLegder from "./Modal/ClientLegder";
export default function AccountStatement() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("ALL");
  const [userName, setUserName] = useState(null);
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [headerAccountLedger, setHeaderAccountLedger] = useState("");
  const [search, setSearch] = useState("");
  const [selectedLogIs, setSelectedLogIs] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();
  const getAccountStatement = async () => {
    let payload = {
      pageNumber: page,
      pageSize: limit,
      type: type,
      startDate: new Date(fromDate),
      endDate: new Date(toDate),
      search,
    };
    if (userName != null && userName?.value?.length > 0) {
      payload["userName"] = userName?.value;
    }
    const { response, message, code } = await accountLogAPI(payload);
    if (code == 200) {
      setData(response);
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  useEffect(() => {
    getAccountStatement();
  }, [page, limit, type, userName, fromDate, toDate, search]);
  return (
    <div className="main">
      <Modal
        allowPinchZoom={true}
        blockScrollOnMount={false}
        size={"6xl"}
        isOpen={isModalOpen}
        onClose={setIsModalOpen}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ClientLegder accountLogId={selectedLogIs} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 main-container">
            <div>
              <div className="listing-grid">
                <div className="detail-row">
                  <h2>Account Statement</h2>
                  <div id="form-search">
                    <div className="m-t-20">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="search-report_type">
                              Account Type
                            </label>
                            <select
                              onChange={(e) => setType(e.target.value)}
                              name="search-report_type"
                              id="search-report_type"
                              className="form-control"
                            >
                              <option value={"ALL"}>All</option>
                              <option value={"BALANCE_REPORT"}>
                                Balance Report
                              </option>
                              <option value={"GAME_REPORT"}>Game Report</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="search-game_name">Game Name</label>
                            <select
                              name="search-game_name"
                              id="search-game_name"
                              className="form-control"
                            >
                              <option value="ALL">All</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="list-ac">
                              Search By Client Name
                            </label>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={searchClient}
                              onChange={(e) => setUserName(e)}
                              isClearable={true}
                              value={userName}
                            />

                            {/* <input
                              type="search"
                              className="form-control"
                              placeholder
                              aria-controls="account_history_table"
                              onChange={(e) => setUserName(e.target.value)}
                            /> */}
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="search-from_date">From</label>
                            <input
                              id="search-from_date"
                              className="form-control datepicker1"
                              name="search-from_date"
                              type="date"
                              value={fromDate}
                              placeholder="Choose Date"
                              onChange={(e) => setFromDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="todate">To</label>
                            <input
                              id="search-to_date"
                              className="form-control datepicker1"
                              name="search-from_date"
                              type="date"
                              value={toDate}
                              placeholder="Choose Date"
                              onChange={(e) => setToDate(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <label style={{ width: "100%" }}>&nbsp;</label>
                          <button
                            className="btn btn-diamond"
                            id="loaddata"
                            onClick={getAccountStatement}
                          >
                            Load
                          </button>
                          {/*<button type="reset" class="btn btn-diamond">View All</button>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="account_history_table_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    className="dataTables_length"
                    id="account_history_table_length"
                  >
                    <label>
                      Show{" "}
                      <select
                        name="account_history_table_length"
                        aria-controls="account_history_table"
                        onChange={(e) => setLimit(e.target.value)}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                  <div
                    id="account_history_table_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      Search:
                      <input
                        type="search"
                        className="form-control"
                        placeholder
                        aria-controls="account_history_table"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </label>
                  </div>
                  <table
                    id="account_history_table"
                    className="table table-striped table-bordered datatable dataTable no-footer"
                    style={{ width: "100%" }}
                    role="grid"
                    aria-describedby="account_history_table_info"
                  >
                    <thead>
                      <tr role="row">
                        <th>S.No.</th>
                        <th
                          width="15%"
                          className="sorting_asc"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="Date: activate to sort column descending"
                          style={{ width: "200px" }}
                        >
                          Date
                        </th>
                        <th
                          width="10%"
                          className="sorting"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Credit: activate to sort column ascending"
                          style={{ width: "125px" }}
                        >
                          Credit
                        </th>
                        <th
                          width="10%"
                          className="sorting"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Debit: activate to sort column ascending"
                          style={{ width: "125px" }}
                        >
                          Debit
                        </th>
                        <th
                          width="10%"
                          className="sorting"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Closing: activate to sort column ascending"
                          style={{ width: "125px" }}
                        >
                          Closing
                        </th>
                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Description: activate to sort column ascending"
                          style={{ width: "571px" }}
                        >
                          Description
                        </th>
                        <th
                          width="15%"
                          className="sorting"
                          tabIndex={0}
                          aria-controls="account_history_table"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Fromto: activate to sort column ascending"
                          style={{ width: "200px" }}
                        >
                          From
                        </th>
                      </tr>
                    </thead>
                    <tbody id="tbody">
                      {data.length == 0 ? (
                        <tr className="odd">
                          <td
                            valign="top"
                            colSpan={6}
                            className="dataTables_empty"
                          >
                            No data available in table
                          </td>{" "}
                        </tr>
                      ) : (
                        data.map((item, index) => (
                          <tr className="odd" key={index}>
                            <td width="60px">{index + 1}</td>
                            <td>
                              {moment(item.createdAt + "Z")
                                .utc()
                                .local()
                                .format("DD/MM/YYYY ")}
                            </td>
                            <td>
                              {item.creditInfo &&
                                parseFloat(item.creditInfo).toFixed(2)}
                            </td>
                            <td>
                              {item.debitInfo &&
                                parseFloat(item.debitInfo).toFixed(2)}
                            </td>
                            <td>
                              {item.currentBalance &&
                                parseFloat(item.currentBalance).toFixed(2)}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (item.type == "GAME_REPORT") {
                                  setSelectedLogIs(item.accountLogId);
                                  setIsModalOpen(true);
                                }
                              }}
                              className={`${
                                item.type == "GAME_REPORT"
                                  ? "label label-default"
                                  : ""
                              }`}
                            >
                              {item.comment}
                            </td>
                            <td>{item.fromTo}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              id="modal_ac_more_details"
              className="modal fade"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                {/* Modal content*/}
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      Client Ledger (Total Win Loss : <span id="total_pl" />)
                      (Total Count : <span id="total_count" />) (Total Soda :{" "}
                      <span id="total_bet" />)
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="table-responsive">
                      <table className="table table-striped jambo_table">
                        <thead>
                          <tr>
                            <td>Uplevel</td>
                            <td>Uername</td>
                            <td>Nation</td>
                            <td>UserRate</td>
                            <td>Amount</td>
                            <td>Win/Loss</td>
                            <td>PlaceDate</td>
                            <td>MatchDate</td>
                            <td>Ip</td>
                            <td>BrowsersDetails</td>
                          </tr>
                        </thead>
                        <tbody id="ac_more_details_tbody" />
                      </table>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ReportMatchbetModal">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="ReportMatchbetHeading">
                      Client Ledger
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className>
                          <form>
                            <input
                              type="hidden"
                              defaultValue
                              id="marketidgamereport"
                            />
                            <div className="account-radio">
                              <input
                                type="radio"
                                defaultValue="all"
                                name="cnt-lgr"
                                id="cnt-lgr1"
                                className="clientledger"
                                defaultChecked
                              />
                              <label htmlFor="cnt-lgr1">All</label>
                              <input
                                type="radio"
                                defaultValue="matched"
                                name="cnt-lgr"
                                id="cnt-lgr2"
                                className="clientledger"
                              />
                              <label htmlFor="cnt-lgr2">Matched</label>
                              <input
                                type="radio"
                                defaultValue="deleted"
                                name="cnt-lgr"
                                id="cnt-lgr3"
                                className="clientledger"
                              />
                              <label htmlFor="cnt-lgr3">Deleted</label>
                            </div>
                          </form>
                        </div>
                        <table
                          id="ReportMatchbettable"
                          className="table table-bordered"
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                        ></table>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
