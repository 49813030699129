import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StatUpArrow,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import {
  ChangeClientGlobalSettingAPI,
  ChangeClientLimitAPI,
  ChangeClientPasswordAPI,
  ChangeClientStatusAPI,
  ClientWithdrawAndDepositAPI,
  getClientAPI,
  getGlobalSettingsAPI,
  searchChildrenAPI,
} from "../../service/UserService";
import { Encrypt } from "../../utils/constants";
import DepositAndWithdraw from "./Forms/DepositAndWithdraw";
import ExposureAndCreditLimit from "./Forms/ExposureAndCreditLimit";
import GlobalSettings from "./Forms/GlobalSettings";
import Password from "./Forms/Password";
import Status from "./Forms/Status";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getUserBetsAPI } from "../../service/AuthService";
import { usePDF } from "react-to-pdf";
import ExportCSV from "../Game/components/ExportToCsv";
export default function ListClient({ activeuser }) {
  const { id, username } = useParams();
  const [clientData, setClientData] = useState([]);
  const [globalSetting, setGlobalSetting] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalAction, setModalAction] = useState("");
  const [payload, setPayload] = useState({});
  const [isBalanceOpen, setIsBalanceOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [size, setSize] = useState(20);
  const [showUserBets, setShowUserBets] = useState(false);
  const [userBets, setUserBets] = useState([]);
  const [isActiveUser, setIsActiveUser] = useState(activeuser);
  const { toPDF, targetRef } = usePDF({ filename: "clients.pdf" });
  const [currentTab, setCurrentTab] = useState("active");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getClient = async () => {
    const { response } = await getClientAPI(
      search,
      currentPage,
      size,
      username
    );
    let response_ = response;
    if (response_) {
      response_.childrenUserResponseDTOS =
        response_.childrenUserResponseDTOSPage.content.sort(
          (a, b) => a.uid - b.uid
        );
      setCurrentPage(response_.childrenUserResponseDTOSPage.number);
      setTotalPages(response_.childrenUserResponseDTOSPage.totalPages);
      setTotalElements(response_.childrenUserResponseDTOSPage.totalElements);
      setSize(response_.childrenUserResponseDTOSPage.size);
    }
    if (!activeuser) {
      if (response_) {
        response_.childrenUserResponseDTOS =
          response_.childrenUserResponseDTOS.filter((item) => {
            if (currentTab == "active") {
              return item.isActive == true;
            } else {
              return item.isActive == false;
            }
          });
      }
      console.log("PPP");
    }
   
    setClientData(response_);
  };

  const nextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const getGlobalSetting = async () => {
    try {
      const { response } = await getGlobalSettingsAPI();
      setGlobalSetting(response);
    } catch {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      window.localStorage.clear();
      window.location.href = "/";
    }
  };
  useEffect(() => {
    getClient();
    getGlobalSetting();
  }, [currentPage, totalPages, totalElements, id, size, currentTab]);
  useEffect(() => {
    setIsActiveUser(activeuser);
  }, [activeuser]);
  const internalSearch = (e) => {
    if (e.target.value.length == 0) {
      getClient();
    }
    if (e.target.value.length > 3) {
      let response_ = clientData.childrenUserResponseDTOS.filter((item) => {
        return (
          item.uid == e.target.value ||
          item?.username
            ?.toLowerCase()
            .includes(e?.target?.value.toLowerCase()) ||
          item?.name?.toLowerCase().includes(e?.target?.value.toLowerCase())
        );
      });
      setClientData({ ...clientData, childrenUserResponseDTOS: response_ });
    }
  };
  const handleModalOpen = (id, user) => {
    let payload_ = { ...payload, id, user: { ...user, ...globalSetting } };
    setPayload(payload_);
    setModalAction(id);
    onOpen();
  };
  const getUserBets = async () => {
    const { response } = await getUserBetsAPI(showUserBets);
    setUserBets(response);
  };
  useEffect(() => {
    getUserBets();
  }, [showUserBets]);
  const getModalBody = () => {
    if (modalAction === "Deposit") {
      return (
        <DepositAndWithdraw
          payload={payload}
          setPayload={setPayload}
          myAvailableBalance={clientData?.myAvailableBalance}
          userName={id}
        />
      );
    } else if (modalAction == "Withdraw") {
      return (
        <DepositAndWithdraw
          payload={payload}
          setPayload={setPayload}
          isWithdraw={true}
          myAvailableBalance={clientData?.myAvailableBalance}
          userName={id}
        />
      );
    } else if (modalAction == "Exposure Limit") {
      return (
        <ExposureAndCreditLimit
          clientData={clientData.childrenUserResponseDTOS}
          payload={payload}
          setPayload={setPayload}
          userName={id}
        />
      );
    } else if (modalAction == "Credit Limit") {
      return (
        <ExposureAndCreditLimit
          payload={payload}
          setPayload={setPayload}
          clientData={clientData.childrenUserResponseDTOS}
          isCreditLimit={true}
          userName={id}
        />
      );
    } else if (modalAction == "Change Password") {
      return <Password payload={payload} setPayload={setPayload} />;
    } else if (modalAction == "Status") {
      return <Status payload={payload} setPayload={setPayload} />;
    } else if (modalAction == "Global Setting") {
      return <GlobalSettings payload={payload} setPayload={setPayload} />;
    }
  };
  const handleModalSubmit = async () => {
    if (
      (payload.transactionPassword == "" ||
        !payload.transactionPassword ||
        payload.transactionPassword.length < 6) &&
      modalAction != "Global Setting"
    ) {
      toast.error("Transaction Password is not valid");
      return;
    }
    let isRequestFailed = false;
    let failMessage = null;
    if (modalAction === "Status") {
      const { response, code, status } = await ChangeClientStatusAPI(
        payload.user.uid,
        payload.isActive,
        payload.isBetActive,
        payload.transactionPassword
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        onClose();
        window.location.reload();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (modalAction == "Exposure Limit" || modalAction == "Credit Limit") {
      const { response, code, status } = await ChangeClientLimitAPI(
        {
          newLimit: payload.newLimit,
          transactionPassword: payload.transactionPassword,
          oldLimit: payload.oldLimit,
          limitType:
            modalAction == "Exposure Limit"
              ? "EXPOSURE_LIMIT"
              : "CREDIT_REFERENCE",
        },
        payload.user.uid
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        onClose();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (modalAction == "Change Password") {
      if (payload.newPassword !== payload.confirmPassword)
        return toast.error("Password and Confirm Password must be same");
      if (payload?.newPassword.length < 5)
        return toast.error("Password must be at least 5 characters long ");
      const { response, code, status } = await ChangeClientPasswordAPI(
        {
          newPassword: Encrypt(payload.newPassword),
          transactionPassword: payload.transactionPassword,
          resetRequired: true,
        },
        payload.user.uid
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        onClose();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (modalAction == "Global Setting") {
      if (
        payload.user.transactionPassword == "" ||
        !payload.user.transactionPassword ||
        payload.user.transactionPassword.length < 6
      ) {
        toast.error("Transaction Password is not valid");
        return;
      }
      let payload_ = payload;
      delete payload_.user.id;
      const { response, code, status } = await ChangeClientGlobalSettingAPI(
        payload_.user,
        payload.user.uid
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        onClose();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (modalAction == "Deposit" || modalAction == "Withdraw") {
      const { response, code, status } = await ClientWithdrawAndDepositAPI(
        payload.user.uid,
        {
          balance: payload.amount,
          transactionPassword: payload.transactionPassword,
          remarks: payload.remarks,
          transactionType: modalAction == "Deposit" ? "DEPOSIT" : "WITHDRAW",
        }
      );
      if (code == 200) {
        toast.success(status);
        setPayload({});
        onClose();
      } else {
        isRequestFailed = true;
        if (response) failMessage = response;
      }
    }
    if (isRequestFailed && failMessage.message) {
      toast.error(
        `Something went wrong while processing ${modalAction} request . Please check your inputs and try again`
      );
    } else if (isRequestFailed) {
      toast.error(failMessage);
    }
    await getClient();
  };
  const handleSearch = async (e) => {
    let searchText = e.target.value;
    e.preventDefault();
    if (searchText == "" || searchText.length < 3) {
      getClient();
      return;
    }
    const { response, code, status } = await searchChildrenAPI(searchText);
    if (code == 200) {
      setClientData(response);
    } else {
      getClient();
    }
  };
  const navigateToAdd = () => {
    navigate("/admin/manage-client/add", {
      state: {
        id: id,
      },
    });
  };
  const navigateInner = (client) => {
    if (client.userType != "CLIENT") {
      window.open(
        `/admin/manage-client/list/${client.username}/${client?.uid}`,
        "_blank"
      );
    }
  };
  return (
    <div className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="master-balance">
            <div
              onClick={() => setIsBalanceOpen(!isBalanceOpen)}
              className="text-center"
            >
              <span
                className="far fa-arrow-alt-circle-down"
                id="user-account_summary"
                style={{ display: isBalanceOpen ? "none" : "inline" }}
              />
              <span
                className="far fa-arrow-alt-circle-up"
                style={{ display: isBalanceOpen ? "inline" : "none" }}
              />
            </div>
            <div
              className="master-balance-detail m-t-20"
              id="master-balance-detail"
              style={{
                position: "relative",
                display: isBalanceOpen ? "block" : "none",
              }}
            >
              <div className="loader-ac_balance" style={{ display: "none" }}>
                <img src="/admin/assets/common/loading.gif" width="40px" />
              </div>
              <ul className="row">
                <li className="col-md-4">
                  <label className="col-md-8 text-left">
                    Upper Level Credit Referance:
                  </label>
                  <span
                    className="text-right col-md-4"
                    id="ac_summary-credit_reference"
                  >
                    {clientData?.summary?.upperLevelCreditReference?.toFixed(2)}
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">
                    Down level Occupy Balance:
                  </label>
                  <span
                    className="text-right col-md-4"
                    id="ac_summary-down_level_balances"
                  >
                    {clientData?.summary?.downLevelOccupancyBalance?.toFixed(2)}
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">
                    Down Level Credit Reference:
                  </label>
                  <span
                    className="text-right col-md-4"
                    id="ac_summary-down_level_credit_reference"
                  >
                    {clientData?.summary?.downLevelCreditReference?.toFixed(2)}
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">
                    Total Master Balance
                  </label>
                  <span
                    className="text-right col-md-4"
                    id="ac_summary-master_balance"
                  >
                    {clientData?.summary?.totalMasterBalance?.toFixed(2)}
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">Upper Level:</label>
                  <span className="text-right col-md-4" id="ac_summary-up_pl">
                    0
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">
                    Down Level Profit/Loss :
                  </label>
                  <span className="text-right col-md-4" id="ac_summary-down_pl">
                    {clientData?.summary?.downLevelPL}
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">
                    Available Balance:
                  </label>
                  <span
                    className="text-right col-md-4"
                    id="ac_summary-available_balance"
                  >
                    {clientData?.summary?.availableBalance?.toFixed(2)}
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">
                    Available Balance With Profit/Loss:
                  </label>
                  <span
                    className="text-right col-md-4"
                    id="ac_summary-available_balance_with_pl"
                  >
                    {clientData?.summary?.availableBalanceWithProfitLoss?.toFixed(
                      2
                    )}
                  </span>
                </li>
                <li className="col-md-4">
                  <label className="col-md-8 text-left">My Profit/Loss:</label>
                  <span className="text-right col-md-4" id="ac_summary-my_pl">
                    {clientData?.summary?.myPl?.toFixed(2)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{modalAction}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{getModalBody()}</ModalBody>
            <ModalFooter>
              <Button
                bg="black"
                size="sm"
                colorScheme="blue"
                mr={3}
                onClick={onClose}
              >
                Back
              </Button>
              <Button onClick={handleModalSubmit} size="sm" bg="#f1c40f">
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          blockScrollOnMount={false}
          isOpen={showUserBets}
          size={"xl"}
          onClose={() => setShowUserBets(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>User Bets</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table variant="simple">
                <thead>
                  <tr>
                    <th>Event Name</th>
                    <th>Market Name</th>
                    <th>UserName</th>
                    <th>Sport</th>
                    <th>Run</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <Tbody>
                  {userBets &&
                    userBets?.content?.map((bet) => (
                      <Tr>
                        <Td>{bet.event_name}</Td>
                        <Td>{bet.marketName}</Td>
                        <Td>{bet.userName}</Td>
                        <Td>{bet.event_type}</Td>
                        <Td>{bet.lay_run ? bet.lay_run : bet?.back_run}</Td>
                        <Td>{bet.back_rate ? bet.back_rate : bet?.lay_rate}</Td>
                        <Td>{bet.amount}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button
                bg="black"
                size="sm"
                colorScheme="blue"
                mr={3}
                onClick={() => setShowUserBets(false)}
              >
                Back
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <div className="row">
          <div className="col-md-12 main-container">
            <div className="listing-grid">
              <div className="detail-row">
                <div className="row">
                  <div className="col-md-6 m-b-10">
                    <h2 className="d-inline-block">Account List</h2>
                  </div>
                  {!id && (
                    <div className="col-md-6 float-right m-b-10">
                      <p className="text-right">
                        <a onClick={navigateToAdd} className="btn btn-diamond">
                          Add Account{" "}
                        </a>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-responsive">
                <div
                  id="clientListTable_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div className="dt-buttons">
                    {" "}
                    <button
                      className="dt-button buttons-pdf buttons-html5"
                      tabIndex={0}
                      aria-controls="clientListTable"
                      type="button"
                      onClick={toPDF}
                    >
                      <span>PDF</span>
                    </button>{" "}
                    <ExportCSV data={clientData?.childrenUserResponseDTOS} />
                  </div>
                  <div
                    id="clientListTable_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      Search:
                      <input
                        type="search"
                        className="form-control"
                        placeholder
                        aria-controls="clientListTable"
                        onChange={handleSearch}
                      />
                    </label>
                  </div>
                  {!activeuser && (
                    <ul
                      role="tablist"
                      className="nav nav-tabs"
                      id="__BVID__111__BV_tab_controls_"
                    >
                      {/**/}
                      <li role="presentation" className="nav-item">
                        <a
                          role="tab"
                          aria-selected="true"
                          aria-setsize={2}
                          aria-posinset={1}
                          onClick={() => setCurrentTab("active")}
                          target="_self"
                          className={`nav-link ${
                            currentTab == "active"
                              ? "active tab-bg-warning"
                              : ""
                          }`}
                          id="__BVID__112___BV_tab_button__"
                          aria-controls="__BVID__112"
                        >
                          Active Users
                        </a>
                      </li>
                      <li role="presentation" className="nav-item">
                        <a
                          role="tab"
                          tabIndex={-1}
                          aria-selected="false"
                          aria-setsize={2}
                          aria-posinset={2}
                          onClick={() => setCurrentTab("deactive")}
                          target="_self"
                          className={`nav-link ${
                            currentTab == "deactive"
                              ? "active tab-bg-warning"
                              : ""
                          }`}
                          id="__BVID__114___BV_tab_button__"
                          aria-controls="__BVID__114"
                        >
                          Deactive Users
                        </a>
                      </li>
                      {/**/}
                    </ul>
                  )}
                  <div className="table-responsive">
                    <div
                      id="clientListTable_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      Show entries
                      <select
                        value={size}
                        style={{ width: "min-content" }}
                        name="clientListTable_length"
                        aria-controls="clientListTable"
                        className="form-control input-sm"
                        onChange={(e) => {
                          setSize(e.target.value);
                        }}
                      >
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  </div>

                  <table
                    ref={targetRef}
                    id="clientListTable"
                    className="table table-striped  table-bordered  no-footer dataTable"
                    style={{ width: "100%" }}
                    role="grid"
                    aria-describedby="clientListTable_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="User Name: activate to sort column descending"
                          style={{ width: "88px" }}
                        >
                          User Name
                        </th>
                        <th
                          className="sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Credit Reference: activate to sort column ascending"
                          style={{ width: "132px" }}
                        >
                          Credit Reference
                        </th>
                        {!activeuser && (
                          <>
                            <th
                              className="sorting_disabled"
                              tabIndex={0}
                              aria-controls="clientListTable"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Balance: activate to sort column ascending"
                              style={{ width: "52px" }}
                            >
                              Balance
                            </th>
                            <th
                              className="sorting_disabled"
                              tabIndex={0}
                              aria-controls="clientListTable"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Client (P/L): activate to sort column ascending"
                              style={{ width: "86px" }}
                            >
                              Client (P/L)
                            </th>
                            <th
                              className="sorting_disabled"
                              tabIndex={0}
                              aria-controls="clientListTable"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Exposure: activate to sort column ascending"
                              style={{ width: "64px" }}
                            >
                              Exposure
                            </th>
                            <th
                              className="sorting_disabled"
                              tabIndex={0}
                              aria-controls="clientListTable"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Available Balance: activate to sort column ascending"
                              style={{ width: "142px" }}
                            >
                              Available Balance
                            </th>
                          </>
                        )}
                        <th
                          className="noExport sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="U St: activate to sort column ascending"
                          style={{ width: "16px" }}
                        >
                          U St
                        </th>
                        <th
                          className="noExport sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="B St: activate to sort column ascending"
                          style={{ width: "16px" }}
                        >
                          B St
                        </th>
                        <th
                          style={{
                            minWidth: "60px",
                            maxWidth: "60px",
                            width: "29px",
                          }}
                          className="sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Exposure Limit: activate to sort column ascending"
                        >
                          Exposure Limit
                        </th>
                        <th
                          className="sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Default %: activate to sort column ascending"
                          style={{ width: "66px" }}
                        >
                          Default %
                        </th>
                        <th
                          className="sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Account Type: activate to sort column ascending"
                          style={{ width: "104px" }}
                        >
                          Account Type
                        </th>
                        <th
                          className="sorting_disabled"
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Account Type: activate to sort column ascending"
                          style={{ width: "104px" }}
                        >
                          Casino Total
                        </th>
                        <th
                          className="noExport sorting_disabled"
                          style={{
                            minWidth: "150px",
                            maxWidth: "150px",
                            width: "257px",
                          }}
                          tabIndex={0}
                          aria-controls="clientListTable"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Actions: activate to sort column ascending"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody id="manage_user_tbody">
                      <tr>
                        <td>TOTAL</td>
                        <td className="amount-field">
                          {parseFloat(clientData?.childrenUserResponseDTOS?.reduce(
                            (a, b) => a + b.creditReference,
                            0
                          )).toFixed(2)}
                        </td>
                        {activeuser ? (
                          <td />
                        ) : (
                          <td className="amount-field">
                            {parseFloat(clientData?.childrenUserResponseDTOS?.reduce(
                              (a, b) => {
                                if (b.userType?.toUpperCase() != "CLIENT") {
                                  return a + b.calculatedBalance;
                                } else {
                                  return a + b.balance;
                                }
                              },
                              0
                            )).toFixed(2)}
                          </td>
                        )}
                        {activeuser ? (
                          <td />
                        ) : (
                          <td className="amount-field">
                            {parseFloat(clientData?.childrenUserResponseDTOS?.reduce(
                              (a, b) => a + b.ownPl,
                              0
                            )).toFixed(2)}
                          </td>
                        )}

                        {activeuser ? (
                          <td className="amount-field">
                            {parseFloat(clientData?.childrenUserResponseDTOS?.reduce(
                              (a, b) => a + b.exposureLimit,
                              0
                            )).toFixed(2)}
                          </td>
                        ) : (
                          <td className="amount-field">
                            {parseFloat(clientData?.childrenUserResponseDTOS?.reduce(
                              (a, b) => a + b.liability,
                              0
                            )).toFixed(2)}
                          </td>
                        )}

                        {activeuser ? (
                          <td />
                        ) : (
                          <td className="amount-field">
                            {parseFloat(clientData?.childrenUserResponseDTOS?.reduce(
                              (a, b) => a + b.balance + b.liability,
                              0
                            )).toFixed(2)}
                          </td>
                        )}

                        <td />
                        <td />
                        {activeuser ? (
                          <td />
                        ) : (
                          <td className="amount-field">
                            {parseFloat(clientData?.childrenUserResponseDTOS?.reduce(
                              (a, b) => a + b.balance + b.liability,
                              0
                            )).toFixed(2)}
                          </td>
                        )}
                        {!activeuser && <td />}
                        {!activeuser && <td />}
                        {!activeuser && <td />}
                        {!activeuser && <td />}
                      </tr>
                      {clientData?.childrenUserResponseDTOS?.map((client) => (
                        <tr role="row" className="even">
                          <td>
                            {" "}
                            <a
                              onClick={() => navigateInner(client)}
                              className="btn label label-default"
                              title="Aaa"
                            >
                              {client.username}
                            </a>
                          </td>
                          <td className="amount-field">
                            {client.creditReference}
                          </td>
                          {!activeuser && (
                            <>
                              <td className="amount-field">
                                {client?.userType?.toLowerCase() != "client"
                                  ? client?.calculatedBalance?.toFixed(2)
                                  : client?.balance?.toFixed(2)}
                              </td>
                              <td className="amount-field">
                                {client?.ownPl?.toFixed(2)}
                              </td>
                              <td
                                onClick={() => setShowUserBets(client.username)}
                                className="amount-field"
                              >
                                {client.liability}
                              </td>
                              <td className="amount-field">
                                {(client?.balance + client?.liability).toFixed(
                                  2
                                )}
                              </td>
                            </>
                          )}
                          <td>
                            {" "}
                            <label className="form-check-label">
                              {" "}
                              <input
                                className="form-check-input"
                                type="checkbox"
                                disabled
                                defaultChecked={
                                  client.isActive ? "checkmark" : "unchecked"
                                }
                              />{" "}
                              <span
                                style={{
                                  backgroundColor: client.isActive
                                    ? ""
                                    : "white",
                                }}
                                className={
                                  client.isActive ? "checkmark" : "checkmark"
                                }
                              />
                            </label>
                          </td>
                          <td>
                            {" "}
                            <label className="form-check-label">
                              {" "}
                              <input
                                className="form-check-input"
                                type="checkbox"
                                disabled
                                defaultChecked={
                                  client.isBetLocked ? "unchecked" : "checkmark"
                                }
                              />
                              <span
                                style={{
                                  backgroundColor: client.isBetLocked
                                    ? "white"
                                    : "",
                                }}
                                className={
                                  client.isBetLocked ? "checkmark" : "checkmark"
                                }
                              />
                            </label>
                          </td>
                          <td className="amount-field">
                            {client.exposureLimit}
                          </td>
                          <td className="amount-field">
                            {client.partnershipInCricket}
                          </td>
                          <td>{client.userType}</td>
                          <td>{0}</td>
                          <td className="actions text-left">
                            <button
                              type="button"
                              title
                              style={{ marginRight: "2px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              className="btn-custom-button btn_deposit_modal"
                              id={57983}
                              data-original-title="Deposit"
                              onClick={() => handleModalOpen("Deposit", client)}
                            >
                              D
                            </button>{" "}
                            <button
                              type="button"
                              title
                              style={{ marginRight: "2px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              className="btn-custom-button btn_withdrawal_modal"
                              id={57983}
                              data-original-title="Withdrawal"
                              onClick={() =>
                                handleModalOpen("Withdraw", client)
                              }
                            >
                              W
                            </button>{" "}
                            {!id && (
                              <>
                                <button
                                  type="button"
                                  title
                                  style={{ marginRight: "2px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  className="btn-custom-button btn_exposure_limit"
                                  id={57983}
                                  data-original-title="Exposure Limit"
                                  onClick={() =>
                                    handleModalOpen("Exposure Limit", client)
                                  }
                                >
                                  L
                                </button>{" "}
                                <button
                                  type="button"
                                  title
                                  style={{ marginRight: "2px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  className="btn-custom-button btn_credit_reference"
                                  id={57983}
                                  data-original-title="Credit Reference"
                                  onClick={() =>
                                    handleModalOpen("Credit Limit", client)
                                  }
                                >
                                  C
                                </button>{" "}
                                <button
                                  type="button"
                                  title
                                  style={{ marginRight: "2px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  className="btn-custom-button btn_change_password"
                                  id={57983}
                                  data-original-title="Change Password"
                                  onClick={() =>
                                    handleModalOpen("Change Password", client)
                                  }
                                >
                                  P
                                </button>{" "}
                                <button
                                  type="button"
                                  title
                                  style={{ marginRight: "2px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  className="btn-custom-button btn_change_status"
                                  id={57983}
                                  data-original-title="Change Status"
                                  onClick={() =>
                                    handleModalOpen("Status", client)
                                  }
                                >
                                  S
                                </button>
                                <button
                                  type="button"
                                  title
                                  style={{ marginRight: "2px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  className="btn-custom-button btn_change_status"
                                  id={57983}
                                  data-original-title="Change Status"
                                  onClick={() =>
                                    handleModalOpen("Global Setting", client)
                                  }
                                >
                                  GS
                                </button>
                                <button
                                  type="button"
                                  title
                                  style={{ marginRight: "2px" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  className="btn-custom-button btn_change_status"
                                  id={57983}
                                  data-original-title="Change Status"
                                  onClick={() =>
                                    navigate(
                                      `/admin/activity-log/${client.username}`
                                    )
                                  }
                                >
                                  A
                                </button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="clientListTable_paginate"
                  >
                    <a
                      className="previous  button btn btn-diamond"
                      aria-controls="clientListTable"
                      data-dt-idx={0}
                      tabIndex={-1}
                      id="clientListTable_previous"
                      onClick={() => prevPage()}
                    >
                      Previous
                    </a>
                    {/* <span>
                      {
                        <a
                        className="current button btn btn-diamond"
                        aria-controls="clientListTable"
                        data-dt-idx={1}
                        tabIndex={0}
                      >
                        1
                      </a>}
                    </span> */}
                    <a
                      className="next  button btn btn-diamond"
                      aria-controls="clientListTable"
                      data-dt-idx={2}
                      tabIndex={-1}
                      id="clientListTable_next"
                      onClick={() => nextPage()}
                    >
                      Next
                    </a>
                  </div>
                </div>
              </div>
              <div className="row m-t-10 m-b-10">
                <div className="col-md-6"></div>
                <div className="col-md-6" id="pagination"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
