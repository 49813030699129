import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
  StatUpArrow,
  Text,
} from "@chakra-ui/react";
import React from "react";

export default function Home() {
  return (
    <Box w="100%" className="home">
      <Text>Coming soon...</Text>
    </Box>
  );
}
