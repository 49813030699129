import { Box, Button, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomTable from "../../components/UI/Table";
import { getMatchDetailsCricketAPI } from "../../service/cricket";
import {
  getMatchDetailsSoccerAPI,
  getMatchDetailsTennisAPI,
} from "../../service/soccer&tennis";
import Cricket from "./Cricket";
import Soccer from "./Soccer";
import Tennis from "./Tennis";

export default function Game() {
  const [gameData, setGameData] = useState({
    gameName: "Cricket",
    gameType: "Match",
    time: "Jan 02,2023 10:00 AM",
  });
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  let items = location.pathname.split("/");
  let gameType = items[items.length - 2];
  let eventId = items[items.length - 1];
  const getCricketMatchDetails = async () => {
    const { response } = await getMatchDetailsCricketAPI(eventId);
    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        gameType,
        settings: response,
        eventId,
      },
    });
  };
  const getMatchDetailsSoccer = async () => {
    const { response } = await getMatchDetailsSoccerAPI(eventId);
    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        gameType,
        settings: response,
        eventId,
      },
    });
  };

  const getMatchDetailsTennis = async () => {
    const { response } = await getMatchDetailsTennisAPI(eventId);
    dispatch({
      type: "gameData/setMatchSettings",
      payload: {
        gameType,
        settings: response,
        eventId,
      },
    });
  };
  useEffect(() => {
    if (userData.token) {
      if (gameType == "soccer") {
        getMatchDetailsSoccer();
      } else if (gameType == "tennis") {
        getMatchDetailsTennis();
      } else if (gameType == "cricket") {
        getCricketMatchDetails();
      }
      setInterval(() => {
        if (gameType == "soccer") {
          getMatchDetailsSoccer();
        } else if (gameType == "tennis") {
          getMatchDetailsTennis();
        } else if (gameType == "cricket") {
          getCricketMatchDetails();
        }
      }, 5000);
    }
  }, []);
  if (gameType === "cricket") {
    return <Cricket />;
  }
  if (gameType === "soccer") {
    return <Soccer />;
  }
  if (gameType === "tennis") {
    return <Tennis />;
  }
}
