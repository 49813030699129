import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { marketAnalysisAPI } from "../service/AuthService";
import moment from "moment/moment";

export default function MyProfitLoss() {
  const [marketAnalysis, setMarketAnalysis] = React.useState([]);
  const dispatch = useDispatch();
  const getMarketAnalysis = async () => {
    const { response } = await marketAnalysisAPI();
    setMarketAnalysis(response);
    if (!response) {
      dispatch({
        type: "accountData/logOut",
        payload: {},
      });
      window.localStorage.clear();
      window.location.href = "/";
    }
  };
  useEffect(() => {
    getMarketAnalysis();
  }, []);
  return (
    <div _ngcontent-oec-c45 className="col-md-12 main-container">
      <div _ngcontent-oec-c45 className="col-md-12 main-container">
        <div _ngcontent-oec-c45 className="listing-grid">
          <div _ngcontent-oec-c45 className="detail-row">
            <h2 _ngcontent-oec-c45>Market Analysis</h2>
            <div _ngcontent-oec-c45 className="m-t-10 m-b-10">
              You can view your cricket card books from sport menu
            </div>
          </div>
          <div _ngcontent-oec-c45 className="table-responsive data-table">
            <table _ngcontent-oec-c45 className="table table-striped">
              <tbody>
                {marketAnalysis.map((game) => (
                  <tr _ngcontent-oec-c45>
                    <td _ngcontent-oec-c45>
                      <a
                        _ngcontent-oec-c45
                        href={`/admin/game/${game?.sport?.toLocaleLowerCase()}/${
                          game.eventId
                        }`}
                      >
                        {game.eventName}{" "}
                        {moment(game.eventDate).format("DD/MM/YYYY HH:mm")}
                      </a>
                    </td>
                    <td _ngcontent-oec-c45>
                      {game?.runnerPlExposureDTOS[0]?.selectionName}{" "}
                      <span
                        _ngcontent-oec-c45
                        className={
                          game?.runnerPlExposureDTOS[0]?.exposure < 0
                            ? "redbx"
                            : "greenbx"
                        }
                      >
                        {parseFloat(
                          game?.runnerPlExposureDTOS[0]?.exposure
                        ).toFixed(2)}
                      </span>
                    </td>
                    <td _ngcontent-oec-c45>
                      {game?.runnerPlExposureDTOS[1]?.selectionName}{" "}
                      <span
                        _ngcontent-oec-c45
                        className={
                          game?.runnerPlExposureDTOS[1]?.exposure < 0
                            ? "redbx"
                            : "greenbx"
                        }
                      >
                        {parseFloat(
                          game?.runnerPlExposureDTOS[1]?.exposure
                        ).toFixed(2)}
                      </span>
                    </td>
                    <td _ngcontent-oec-c45>
                      The Draw{" "}
                      <span
                        _ngcontent-oec-c45
                        className={
                          game?.runnerPlExposureDTOS[2]?.exposure < 0
                            ? "redbx"
                            : "greenbx"
                        }
                      >
                        {parseFloat(
                          game?.runnerPlExposureDTOS[2]?.exposure
                            ? game?.runnerPlExposureDTOS[2]?.exposure
                            : 0
                        ).toFixed(2)}
                      </span>
                    </td>
                    <td _ngcontent-oec-c45>
                      Total Bets{" "}
                      <span _ngcontent-oec-c45 className="greenbx">
                        {game.totalBets}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
